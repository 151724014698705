import React, {
  useMemo,
  useState,
  createContext,
  useContext,
  useEffect,
} from 'react'
import type { ReactNode } from 'react'
import type { StampsProductItem, StampsShelfItem } from 'src/typings/stamps' // Importando os tipos corretos

import { useCmsGlobalComponents } from './CMSGlobalComponents/cms-global-components-context'

export interface IContext {
  stampsData: Array<StampsProductItem | StampsShelfItem>
  setStampsData: React.Dispatch<Array<StampsProductItem | StampsShelfItem>>
}

const StampsDataContext = createContext<IContext>({} as IContext)

export const StampsDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [stampsData, setStampsData] = useState<
    Array<StampsProductItem | StampsShelfItem>
  >([])

  const { cmsGlobalComponents } = useCmsGlobalComponents()

  useEffect(() => {
    if (cmsGlobalComponents?.sections) {
      const stamps = cmsGlobalComponents?.sections?.find(
        (section: { name: string }) => section.name === 'Selos'
      )?.data?.allItems

      if (stamps) {
        setStampsData(stamps)
      }
    }
  }, [cmsGlobalComponents?.sections])

  const value = useMemo(
    () => ({
      stampsData,
      setStampsData,
    }),
    [stampsData]
  )

  return (
    <StampsDataContext.Provider value={value}>
      {children}
    </StampsDataContext.Provider>
  )
}

export const useStampsDataContext = () => {
  return useContext(StampsDataContext)
}
